.solutions-grid {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;

    @include medium-up {
      margin-bottom: 3px;
      overflow: hidden;
    }
  }

  li {
    display: block;
    margin: 0 0 1px 0;
    padding-bottom: 25%;
    min-height: 160px;
    height: 0;
    position: relative;

    @include medium-up {
      padding: 0;
      float: left;
      width: calc(100% / 2);
      height: 200px;
      border-right: 1px solid white;

      &:nth-child(2n+2) {
        border-right: 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include large-up {
      width: calc(100% / 3);

      &:nth-child(2n+2) {
        border-right: 1px solid white;
      }

      &:nth-child(3n+3) {
        border-right: 0;
      }
    }
  }

  a {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 60px 20px 0 20px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    background-color: $limedspruce;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    @include medium-up {
      padding-top: 22%;
    }

    @include large-up {
      display: flex;
      align-items: center;
      padding: 0 40px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blackpearl, 0.8);
      z-index: 90;
    }

    &:visited {
      background-color: $limedspruce;
    }

    &:focus,
    &:hover {
      background-color: $supernova;

      &:before {
        background-color: rgba($supernova, 0.88);
      }

      span {
        color: $limedspruce;

        &.title {
          color: $limedspruce;
        }
      }
    }
  }

  span {
    position: relative;
    display: block;
    @include fontsize(14 18);
    font-weight: 700;
    letter-spacing: 0.046875em;
    color: $supernova;
    text-transform: uppercase;
    z-index: 91;

    &.title {
      font-family: $factoria;
      @include fontsize(20 22);
      font-weight: 500;
      color: white;
    }
  }
}