/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  color: black;
  background: white;
}

.offscreen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: "";
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media (orientation: landscape) {
  .show-landscape {
    display: block;
  }
}
@media (orientation: portrait) {
  .show-landscape {
    display: none;
  }
}

@media (orientation: portrait) {
  .show-portrait {
    display: block;
  }
}
@media (orientation: landscape) {
  .show-portrait {
    display: none;
  }
}

@media (orientation: landscape) {
  .hide-landscape {
    display: none;
  }
}
@media (orientation: portrait) {
  .hide-landscape {
    display: block;
  }
}

@media (orientation: portrait) {
  .hide-portrait {
    display: none;
  }
}
@media (orientation: landscape) {
  .hide-portrait {
    display: block;
  }
}

@media (min-width: 640px) {
  .show-small-only {
    display: none;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .show-medium-only {
    display: none;
  }
}
@media (min-width: 1024px) {
  .show-medium-only {
    display: none;
  }
}

.show-large-only {
  display: none;
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .show-large-only {
    display: block;
  }
}
@media (min-width: 1200px) {
  .show-large-only {
    display: none;
  }
}

.show-xlarge-only {
  display: none;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .show-xlarge-only {
    display: block;
  }
}
@media (min-width: 1400px) {
  .show-xlarge-only {
    display: none;
  }
}

.show-xxlarge-only {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-only {
    display: none;
  }
}

.show-medium-up {
  display: none;
}
@media (min-width: 640px) {
  .show-medium-up {
    display: block;
  }
}

.show-large-up {
  display: none;
}
@media (min-width: 1024px) {
  .show-large-up {
    display: block;
  }
}

.show-xlarge-up {
  display: none;
}
@media (min-width: 1200px) {
  .show-xlarge-up {
    display: block;
  }
}

.show-xxlarge-up {
  display: none;
}
@media (min-width: 1400px) {
  .show-xxlarge-up {
    display: block;
  }
}

@media (min-width: 0) and (max-width: 639px) {
  .hide-small-only {
    display: none;
  }
}

@media (min-width: 640px) and (max-width: 1023px) {
  .hide-medium-only {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .hide-large-only {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .hide-xlarge-only {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-only {
    display: none;
  }
}

@media (min-width: 640px) {
  .hide-medium-up {
    display: none;
  }
}

@media (min-width: 1024px) {
  .hide-large-up {
    display: none;
  }
}

@media (min-width: 1200px) {
  .hide-xlarge-up {
    display: none;
  }
}

@media (min-width: 1400px) {
  .hide-xxlarge-up {
    display: none;
  }
}

.rotator {
  margin-bottom: 4px;
  height: auto;
}
@media (min-width: 1024px) {
  .rotator {
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .rotator {
    height: 600px;
  }
}
.rotator .banner {
  background-color: #003da7;
}
@media (min-width: 1024px) {
  .rotator .banner {
    display: flex;
    height: 500px;
  }
}
@media (min-width: 1200px) {
  .rotator .banner {
    height: 600px;
  }
}
.rotator .banner .img {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 640px) and (max-width: 1023px) {
  .rotator .banner .img {
    height: 320px;
  }
}
@media (min-width: 1024px) {
  .rotator .banner .img {
    height: auto;
    width: 66.66%;
  }
}
.rotator .banner .text {
  padding: 60px 20px 50px 20px;
  background-image: url("../images/bg-banner-logo.svg");
  background-position: bottom right;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
@media (min-width: 1024px) {
  .rotator .banner .text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 33.33%;
    padding-left: 40px;
    padding-right: 40px;
  }
}
.rotator .banner .text .heading {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
  font-family: "Rokkitt", serif;
  font-size: 35px;
  line-height: 28px;
  font-size: 2.1875rem;
  line-height: 1.75rem;
  letter-spacing: normal;
  text-transform: uppercase;
  border-bottom: 2px solid #1884ec;
}
.rotator .banner .text .caption {
  display: block;
  font-size: 17px;
  line-height: 28px;
  font-size: 1.0625rem;
  line-height: 1.75rem;
  letter-spacing: normal;
}

.solutions-grid ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
@media (min-width: 640px) {
  .solutions-grid ul {
    margin-bottom: 3px;
    overflow: hidden;
  }
}
.solutions-grid li {
  display: block;
  margin: 0 0 1px 0;
  padding-bottom: 25%;
  min-height: 160px;
  height: 0;
  position: relative;
}
@media (min-width: 640px) {
  .solutions-grid li {
    padding: 0;
    float: left;
    width: 50%;
    height: 200px;
    border-right: 1px solid white;
  }
  .solutions-grid li:nth-child(2n+2) {
    border-right: 0;
  }
}
.solutions-grid li:last-child {
  margin-bottom: 0;
}
@media (min-width: 1024px) {
  .solutions-grid li {
    width: 33.3333333333%;
  }
  .solutions-grid li:nth-child(2n+2) {
    border-right: 1px solid white;
  }
  .solutions-grid li:nth-child(3n+3) {
    border-right: 0;
  }
}
.solutions-grid a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 60px 20px 0 20px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  background-color: #323e48;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (min-width: 640px) {
  .solutions-grid a {
    padding-top: 22%;
  }
}
@media (min-width: 1024px) {
  .solutions-grid a {
    display: flex;
    align-items: center;
    padding: 0 40px;
  }
}
.solutions-grid a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 14, 26, 0.8);
  z-index: 90;
}
.solutions-grid a:visited {
  background-color: #323e48;
}
.solutions-grid a:focus, .solutions-grid a:hover {
  background-color: #ffc800;
}
.solutions-grid a:focus:before, .solutions-grid a:hover:before {
  background-color: rgba(255, 200, 0, 0.88);
}
.solutions-grid a:focus span, .solutions-grid a:hover span {
  color: #323e48;
}
.solutions-grid a:focus span.title, .solutions-grid a:hover span.title {
  color: #323e48;
}
.solutions-grid span {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: normal;
  font-weight: 700;
  letter-spacing: 0.046875em;
  color: #ffc800;
  text-transform: uppercase;
  z-index: 91;
}
.solutions-grid span.title {
  font-family: "Rokkitt", serif;
  font-size: 20px;
  line-height: 22px;
  font-size: 1.25rem;
  line-height: 1.375rem;
  letter-spacing: normal;
  font-weight: 500;
  color: white;
}