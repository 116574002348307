.rotator {
  margin-bottom: 4px;
  height: auto;

  @include large-up {
    height: 500px;
  }

  @include xlarge-up {
    height: 600px;
  }

  .banner {
    background-color: $cobalt;

    @include large-up {
      display: flex;
      height: 500px;
    }

    @include xlarge-up {
      height: 600px;
    }

    .img {
      width: 100%;
      height: 240px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @include medium-only {
        height: 320px;
      }

      @include large-up {
        height: auto;
        width: 66.66%;
      }
    }

    .text {
      padding: 60px 20px 50px 20px;
      background-image: url('../images/bg-banner-logo.svg');
      background-position: bottom right;
      background-size: cover;
      background-repeat: no-repeat;
      color: white;

      @include large-up {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 33.33%;
        padding-left: 40px;
        padding-right: 40px;
      }

      .heading {
        display: block;
        margin-bottom: 20px;
        padding-bottom: 20px;
        font-family: $factoria;
        @include fontsize(35 28);
        text-transform: uppercase;
        border-bottom: 2px solid $dodgerblue;
      }

      .caption {
        display: block;
        // padding: 0 20px;
        @include fontsize(17 28);
      }
    }
  }
}